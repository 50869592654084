
@font-face {
    font-family: 'Roboto';
    src: url('RobotoCondensed-Regular.ttf');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('RobotoCondensed-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}



